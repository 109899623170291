import React from 'react'
import {FormControl, MenuItem, Select, InputLabel, TextField} from '@mui/material'

const SeriesSelect = ({ onChange, seasonNumber })=>{
    return(
        <div>
            <FormControl fullWidth>
                <InputLabel className='mt-2' id="season-select-label">Seasons</InputLabel>
                <Select className='my-2' labelId="season-select-label" id="season-select-label" value={seasonNumber} label="Season" placeholder='Seasons' variant='outlined'
                    onChange={onChange}>
                    <MenuItem value={1}>Season 1</MenuItem>
                    <MenuItem value={2}>Season 2</MenuItem>
                    <MenuItem value={3}>Season 3</MenuItem>
                    <MenuItem value={4}>Season 4</MenuItem>
                    <MenuItem value={5}>Season 5</MenuItem>
                </Select>
            </FormControl>
        </div>
    )
}

export default SeriesSelect;