import React, {createContext, useState} from 'react'
import { useEffect } from 'react'
import { instance } from '../axiosConfig'

export const AuthContext = createContext()

const AuthContextProvider = ({ children })=>{
    const [user, setVendor] = useState({vendor_name:'', vendor_email:'', creation_date:''})

    const getUserVendor = async()=>{
        let userId = localStorage.getItem('userId')
        const res =  await instance.get(`/vendor/${userId}`)

        if(res.data.status === 200){
            setVendor({...user, vendor_name:res.data.vendor.vendor_name, vendor_email:res.data.vendor.email, creation_date:''})
        }
    }

    useEffect(()=>{
        getUserVendor()
    },[])
 
    return (
        <AuthContext.Provider
            value={{
                user,
                getUserVendor
            }} >
            {children}
        </AuthContext.Provider>
    );
    
}

export default AuthContextProvider

