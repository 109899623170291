import axios from 'axios';

export let instance

switch(process.env.REACT_APP_ENV){
    case "local":
        instance = axios.create({ baseURL: process.env.REACT_APP_BASE_URL_LOCAL});
        break;
    case "development":
        instance = axios.create({ baseURL: process.env.REACT_APP_BASE_URL_DEV }); //https://dev-api.afrowatch.net 
        break;
    case "production":
        instance = axios.create({ baseURL: process.env.REACT_APP_BASE_URL_PROD }); //https://api.afrowatch.net 
        break;
    default:
        instance = axios.create({ baseURL: process.env.REACT_APP_BASE_URL_LOCAL });
}

