import React from 'react'
import { useLocation } from 'react-router-dom'
import SearchBar from '../Movies/Search/Search'
import NavSide from '../Dashboard/NavSide'
import {Container, Grid, Button, Box, Tab, Tabs, Typography} from '@mui/material'

const MovieDetails = (props)=>{
    const location = useLocation()
    console.log(location)
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    function a11yProps(index) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    function TabPanel(props) {
        const { children, value, index, ...other } = props;
      
        return (
          <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
              <Box sx={{ p: 3 }}>
                <Typography>{children}</Typography>
              </Box>
            )}
          </div>
        );
    }


    return(
        <Container maxWidth='lg'>
            <SearchBar input=''/>
            <NavSide/>
            <Grid className='mt-5' container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }} >
                <Grid item xs={2} md={6}>
                    <div className="text-left" style={{position:'relative', left:'120px'}}>
                        <p>Content Name: {location.state.movieName}</p>
                        <p>Year: {location.state.year}</p>
                        <p>Description: {location.state.description}</p>
                        <p>Genre: {location.state.genre}</p>
                        <p>Days Left: {location.state.licensedDate}</p>
                    </div>
                </Grid>

                <Grid item xs={2} md={6}>
                    <div className='mt-4'>
                        <Button className='mx-3' variant="contained" color='success'>Extend</Button>
                        <Button variant="contained" color='error'>Terminate</Button>
                    </div>
                </Grid>

            </Grid>

            <Grid className='justify-content-center mt-4' container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 5 }} >
                <Grid item xs={2} md={10}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            {/* <Tab label="LICENSE" {...a11yProps(0)} />
                            <Tab label="BROKER" {...a11yProps(1)} /> */}
                            <Tab label="MONITORING" {...a11yProps(0)} />
                            <Tab label="CONTACT" {...a11yProps(1)} />
                            <Tab label="DOCUMENTS" {...a11yProps(2)} />
                            <Tab label="SALES" {...a11yProps(3)} />
                            <Tab label="LEGAL" {...a11yProps(4)} />
                            <Tab label="HELP" {...a11yProps(5)} />
                        </Tabs>
                    </Box>
                    {/* <TabPanel value={value} index={0}>
                        LICENSE
                    </TabPanel > */}
                    {/* <TabPanel value={value} index={1}>
                       BROKER
                    </TabPanel > */}
                    <TabPanel value={value} index={0}>
                       MONITORING
                    </TabPanel >
                    <TabPanel value={value} index={1}>
                       CONTACT
                    </TabPanel >
                    <TabPanel value={value} index={2}>
                       DOCUMENTS
                    </TabPanel >
                    <TabPanel value={value} index={3}>
                       SALES
                    </TabPanel >
                    <TabPanel value={value} index={4}>
                       LEGAL
                    </TabPanel >
                    <TabPanel value={value} index={5}>
                       HELP
                    </TabPanel >
                </Grid>
            </Grid>
        </Container>
    )

}

export default MovieDetails;