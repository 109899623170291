const GENRE =  {
    Action: 'Action',
    Adventure: 'Adventure',
    Afrime: 'Afrime',
    Comedy: 'Comedy',
    Documentary: 'Documentary',
    Drame: 'Drama',
    Family: 'Family',
    Fantasy:'Fantasy',
    Horror: 'Horror',
    Musical: 'Musical',
    Mystery: 'Mystery',
    Romance: 'Romance',
    SciFi:'Science Fiction',
    Sports:'Sports',
    Suspense: 'Suspense',
    Teen:'Teen',
    Thriller:'Thriller',
    War:'War'
}

const GENRES =  [
    'Action',
    'Adventure',
    'Afrime',
    'Comedy',
    'Documentary',
    'Drama',
    'Family',
    'Fantasy',
    'Horror',
    'Musical',
    'Mystery',
    'Romance',
    'Science Fiction',
    'Sports',
    'Suspense',
    'Teen',
    'Thriller',
    'War'
]

const COUNTRIES =  [
    'Nigeria',
    'Ghana',
    'South Africa',
    'Ethiopia',
    'Namibia',
    'Cameroon',
    'Somalia',
    'Morocoo',
    'Other'
]

const SUBLANGUAGES =  [
    {
        language: 'en',
        label: 'English'
    },
    {
        language: 'yb',
        label: 'Yoruba'
    },
    {
        language: 'igb',
        label: 'Igbo'
    },
    {
        language: 'fr',
        label: 'French'
    },
    {
        language: 'xh',
        label: 'Xhosa'
    },

]

const CONTENT_TYPE =['MOVIE', 'SERIES', 'SHORTS']
const VIDEO_EXTENSION =['.mp4','.avi','.mov', '.wmv','.flv', '.mkv', '.webm',' .mpeg', '.mpg','3gp', '.m4v']

module.exports = GENRE
module.exports.Genres = GENRES
module.exports.ContentType = CONTENT_TYPE
module.exports.VideoExtension = VIDEO_EXTENSION
module.exports.Countries = COUNTRIES
module.exports.SubtitleLanguages = SUBLANGUAGES