import React, { useEffect } from 'react'
import axiosInstance from '../../axiosConfig'
import SearchBar from  '../Movies/Search/Search'
import NavSide from '../Dashboard/NavSide'
import { Container, Grid } from '@mui/material';
import { BarChart  } from '@mui/x-charts/BarChart';
import { LineChart } from '@mui/x-charts/LineChart';
import { PieChart } from '@mui/x-charts/PieChart';
import './Activity.css'

const Activiy=()=>{
    useEffect(()=>{
        //mkae request to get view count and other KPIs

    })


    return(
        <div>
            <SearchBar searchInput=''/>
            <NavSide/>
            <Grid container justifyContent="center" marginTop={10} rowSpacing={2} columnSpacing={{ xs: 10, sm: 20, md: 30 }}>
                <Grid item xs={2} sm={4} md={3} >
                    <div className='chart-skeleton'>
                    <LineChart
                        xAxis={[{ data: [1, 2, 3, 5, 8, 10], label:'Content name' }]}
                        yAxis={[{ label:'Unique views'}]}
                        series={[ 
                            {data: [2, 5.5, 2, 8.5, 1.5, 5],}, 
                        ]}
                        width={400}
                        height={220}
                    />
                    </div>
                </Grid>

                <Grid item xs={2} sm={4} md={3}>
                    <div className='chart-skeleton'>
                    <BarChart
                        xAxis={[{ scaleType: 'band', data: ['2021', '2022', '2023','2024', '2025'], label:'Genres'}]} 
                        yAxis={[{ label:'Most watched'}]}
                        series={[
                            { id: 'Action', label: 'Action', data: [4, 3, 5] }, 
                            { id: 'Afrime', label: 'Afrime', data: [1, 6, 3] }, 
                            { id: 'Drama', label: 'Drama', data: [2, 5, 6] }
                        ]}
                        width={400}
                        height={220}
                    />
                    </div>
                </Grid>

                <Grid item xs={2} sm={4} md={3}>
                    <div className='chart-skeleton-pie'>
                    <PieChart
                        series={[
                            {
                                data: [
                                    { id: 0, value: 10, label: 'series A' },
                                    { id: 1, value: 15, label: 'series B' },
                                    { id: 2, value: 20, label: 'series C' },
                                ],
                            },
                        ]}
                        width={380}
                        height={170}
                    />
                    </div>
                </Grid>
            </Grid>

            <Grid container justifyContent="center" marginTop={10} rowSpacing={2} columnSpacing={{ xs: 10, sm: 20, md: 30 }}>
                <Grid item xs={2} sm={4} md={3} >
                    <div className='chart-skeleton'>
                    <LineChart
                        xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
                        series={[ {data: [2, 5.5, 2, 8.5, 1.5, 5],}, ]}
                        width={400}
                        height={220}
                    />
                    </div>
                </Grid>

                <Grid item xs={2} sm={4} md={3} >
                    <div className='chart-skeleton'>
                    <LineChart
                        xAxis={[{ data: [1, 2, 3, 5, 8, 10], label:'Content name' }]}
                        yAxis={[{ label:'Unique views'}]}
                        series={[ 
                            {data: [2, 5.5, 2, 8.5, 1.5, 5],}, 
                        ]}
                        width={400}
                        height={220}
                    />
                    </div>
                </Grid>

                <Grid item xs={2} sm={4} md={3}>
                    <div className='chart-skeleton'>
                    <BarChart
                        xAxis={[{ scaleType: 'band', data: ['2021', '2022', '2023','2024', '2025'], label:'Genres'}]} 
                        yAxis={[{ label:'Most watched'}]}
                        series={[
                            { id: 'Action', label: 'Action', data: [4, 3, 5] }, 
                            { id: 'Afrime', label: 'Afrime', data: [1, 6, 3] }, 
                            { id: 'Drame', label: 'Drama', data: [2, 5, 6] }
                        ]}
                        width={400}
                        height={220}
                    />
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default Activiy