import React,{useEffect, useState} from 'react';
import {Tabs, Tab} from 'react-bootstrap';
import Signup from './Signup'
import Login from './Login.jsx';
import NavBar from '../../Navbar/Navbar';
import '../../Home/Home.css';
import './Independent.css'

const Authentication=()=>{
    return(
        <div className="bg-sec-1">
            <NavBar/>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-5">
                        <img style={{opacity:'0'}} className="login-afrowatch-logo" src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1618744943/AfroWatch/Afrowatch-Logo--Vertical-White-font_h6sxho.png" alt=""/>
                       
                        <div className="card pt-2 pb-5 border-0">
                            <div>
                                <img className="login-afrowatch-logo" src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1618744943/AfroWatch/Afrowatch-Logo--Vertical_n7jysw.png" alt=""/>
                            </div>

                            <Tabs className="justify-content-center mt-3" defaultActiveKey="login" id="">
                                <Tab eventKey="login" title="Login">
                                    <Login/>
                                </Tab>

                                <Tab eventKey="signup" title="Signup">
                                    <Signup />
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Authentication;