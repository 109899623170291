import React from 'react'
import {BrowserRouter} from 'react-router-dom'
import {Route, Routes} from 'react-router'
import Home from './Home/Home'
import Dashboard from './Dashboard/Dashboard'
import Movies from './Movies/Movies'
import MovieDetails from './MovieDetails/MovieDetail'
import Activity from './Statistics/Activity'
import Help from './Help/Help'
import Account from './Account/Account'
import Independent from './Authentication/Independent/Independent'
import AuthContextProvider from '../context/AuthContext'
import './App.css';

function App() {
  return (
	<BrowserRouter>
		<AuthContextProvider>
			<div className="App">
				<Routes>
					<Route path="/"  element={<Home/>}/>
					<Route path="/independent"  element={<Independent/>}/>
					<Route path="/login"  element={<Home/>}/>
					<Route path="/console"  element={<Dashboard/>}/>
					<Route path="/account"  element={<Account/>}/>
					<Route path="/activity"  element={<Activity/>}/>
					<Route path="/content"  element={<Movies/>}/>
					<Route path="/help" element={<Help/>}/>
					<Route exact path="/movie/:id/info"  element={<MovieDetails/>}/>
				</Routes>
			</div>
		</AuthContextProvider>
	</BrowserRouter>
  );
}
export default App;
