import React, {useState,useEffect,useContext} from 'react'
import {Box, FormControl, TextField, OutlinedInput, InputLabel} from '@mui/material'
import Intercom from '@intercom/messenger-js-sdk';
import { instance } from '../../axiosConfig'
import SideNav from '../Dashboard/NavSide'
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';
import { green } from '@mui/material/colors';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { AuthContext } from '../../context/AuthContext';

const Account=()=>{
    const [vendor, setVendor] = useState({vendor_name:'', vendor_email:'', creation_date:'', current_password:'', new_password:''})
    const [showPassword, setShowPassword] = useState(false);
    const [alert, setAlert] = useState(false)
    const [progress, setProgress] = useState(false)
    const [message, setMessage]= useState('')
    const { user } = useContext(AuthContext)

    const getVendor = async()=>{
        let userId = localStorage.getItem('userId')
        const res =  await instance.get(`/vendor/${userId}`)

        if(res.data.status === 200){
            //console.log(res.data)
            setVendor({...vendor, vendor_name:res.data.vendor.vendor_name, vendor_email:res.data.vendor.email})
        }
    }

    const changePassword = async(e)=>{
        e.preventDefault()

        setProgress(true)

        try{
            const res = await instance.post('/changepassword', {email:vendor.vendor_email, currentPassword:vendor.current_password.trim(), newPassword:vendor.new_password.trim()})
            if(res.data.status === 200){
                setMessage(res.data.message)
                setAlert(true)
                setProgress(false)
            }else{
                setMessage(res.data.message)
                setAlert(true)
                setProgress(false)
            }

        }catch(e){
            console.log(e)
            setMessage(e)
            setAlert(true)
        }
    }

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (e) => {e.preventDefault();};

    useEffect(()=>{
        getVendor()
    },[])

    const handleSnackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setAlert(false);
    };

    const action = (
        <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackBarClose}>
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    Intercom({
        app_id: process.env.REACT_APP_INTERCOM_ID,
        user_id: localStorage.getItem('userId'), 
        name: vendor.vendor_name, 
        email: vendor.vendor_email, 
        created_at: vendor.creation_date, 
    });

    return(
        <div>
            <SideNav/>
            <div className="container">
                <div className="row mt-4 justify-content-center">
                    <h4>My Account</h4>
                    <div className="col-md-8">
                        <Box sx={{ p: 6, marginY:4, border: '1px solid black' }}>

                            {alert ? <Snackbar open={alert} autoHideDuration={5000} onClose={handleSnackBarClose} message={message} action={action} /> : null}

                            <FormControl fullWidth>   
                                <form onSubmit={changePassword} action="/" method='POST'>                    
                                    <div>
                                        <TextField required placeholder='Vendor name' disabled
                                            id="outlined-basic" className='my-3 text-content-width'  name="vendor_name" label="Vendor name" variant="outlined" value={vendor.vendor_name} 
                                        /> <br/>
                                        
                                        <TextField required disabled
                                            id="outlined-basic" className='my-3 text-content-width'  name="vendor_email" label="Vendor email" variant="outlined" value={vendor.vendor_email} 
                                        /> <br/>

                                        <InputLabel htmlFor="outlined-adornment-password" style={{top:'193px'}}>Current password</InputLabel>
                                        <OutlinedInput required
                                            onChange={(e)=>setVendor({...vendor, current_password:e.target.value})}  placeholder="Current passsword"  type={showPassword ? 'text' : 'password'}
                                            id="outlined-basic" className='my-3 text-content-width'  name="current_password" label="Current Password" variant="outlined" value={vendor.current_password} 
                                            endAdornment={
                                                <InputAdornment position="end">
                                                  <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end">
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                  </IconButton>
                                                </InputAdornment>
                                            }
                                        /> <br/>    

                                        <TextField required
                                            placeholder='New password' onChange={(e)=>setVendor({...vendor, new_password:e.target.value})}  type={showPassword ? 'text' : 'password'}
                                            id="outlined-basic" className='my-3 text-content-width'  name="new_password" label="New Password" variant="outlined" value={vendor.new_password} 
                                        /> <br/>
                                    </div>

                                    <input className='form-control' style={{borderColor:'#220B21', marginTop:'15px', marginBottom:'15px'}} type="submit" />

                                    {progress ? 
                                        <div> 
                                            <CircularProgress
                                                size={15}
                                                sx={{
                                                color: green[500],
                                                zIndex: 1,
                                                }}
                                            /> 
                                        </div>:null
                                    }
                                </form>
                            </FormControl>
                        </Box>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Account;