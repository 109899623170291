import React, { useState, useEffect } from "react";
import SearchBar from './Search/Search'
import NavSide from '../Dashboard/NavSide'
import MovieCard from './Cards';
import { Container, Grid } from '@mui/material';
import '../Dashboard/NavSide.css'
import { Link } from "react-router-dom";

const Movies =()=>{
    const [movies, setMovies] = useState([])

    const getMovies= async (input)=>{
        //get request to content
        const vendorId =  localStorage.getItem('userId')
        const vendorName = localStorage.getItem('vendorName')
        
        const axiosInstance = require('../../axiosConfig')
        //Any space in a vendor name is replaced with a hyphen (-) when stored in DynamoDB. AWS services dont like spaces
        const resp = await axiosInstance.instance.get(`/content/${vendorId}/${vendorName.replace(/[\s!@#$%^&*()_+[\]{};:'",<>?~`]/g, '-')}`)
        
        if(resp.data.status ===  200){
            console.log(resp.data.content)

            let movies = resp.data.content
            setMovies(movies)
        }else{
            setMovies([])
        }
    }

    useEffect(()=>{
        //getMoviesPreview()
        getMovies()
    },[])
   

    return(
       <div>
            <SearchBar searchInput=''/>
            <NavSide/>
            {/* add movie filter */}
            <div className="container mt-5">
                <div className="row mt-5">
                    <div className="col-md-12">
                        <h4 className="mt-5">My Content</h4>
                    </div>
                </div>
            </div>
            
            <Container className="movie-card-container" maxWidth='md'>
                <Grid container rowSpacing={2} columnSpacing={{ xs: 10, sm: 20, md: 30 }}>
                    { movies && movies.length > 0 ? movies.map((movie, index)=>{
                        return <Grid item xs={2} sm={4} md={3} key={index}>
                                <MovieCard key={movie.content_id} id={movie.content_id} movieName={movie.content_name} duration={movie.duration} licensedFrom={movie.day_uploaded} hoursWatched={movie.hoursStreamed} 
                                    trending={movie.isTrending ? 'Yes' : 'No'} genre={movie.genre} releaseYear={movie.released} description={movie.content_description}/>
                            </Grid>
                    }) : <div className="text-center" style={{marginLeft:'200px', 
                    background:'#2D132C', 
                    border:'1px solid #C170C1',
                    borderRadius:'10px',
                    padding:'10px',
                    width:'100%',
                    fontWeight:'bold',
                    color:'white'
                    }}>No content yet for vendor. {<Link style={{color:'#C170C1'}} to={'/console'}>Upload ?</Link>}</div>}
                </Grid>
            </Container>
            
        </div>
    )
}

export default Movies;