import React from 'react'
import { useState, useEffect } from 'react';
import './Search.css'

const Search=(props)=>{
    useEffect(()=>{
       
    },[])

    const[initialTextState, setText] = useState('')

    const getFirstTenMovies=()=>{

    }
    
    const onFormSubmit=()=>{}

    return(         
        <div className='bg-dark py-1'>
            <form onSubmit={onFormSubmit} action="" method="post">
                <input className="search border-0 mt-md-2" type="search" name="" id="" placeholder="Search" value={initialTextState} onChange={(e)=>setText(e.target.value)}/>
            </form>
        </div>
    )
}

export default Search;